<template>
	<div class="page-not-found">
		<div class="row center-content text-center">
			<div class="col-lg-12">
				<h4 class="mb-3">Maaf! Halaman tidak ditemukan.</h4>
				<strong class="badge bg-secondary"><h1 class="display-4">404</h1></strong>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PageNotFound',
	data(){
		return{
		}
	}
}
</script>