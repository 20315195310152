<template>
	<div class="kontak">
		<div class="loader" v-if="loading">
			<div class="row centered-loading">
				<div class="col-lg-12">	
					<div class="spinner-grow text-info" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</div>
				<div class="col-lg-12 text-light">	
					mengirim data ..
				</div>	
			</div>
		</div>
		<div class="container mt-5">
			<div class="row g-3">
				<div class="col-lg-6">	
					<h3 class="card-title mt-0">Hubungi Kami</h3>
					<h6 class="card-subtitle mt-0 mb-5">Jangan sungkan untuk menghubungi kami jika ada pertanyaan terkait ekosistem perbukuan.</h6>
					<div class="row mt-3">
						<div class="col-lg-12">
							<span><img src="../assets/admin-sibi.png" width="15" alt=""></span>
							<p class="ms-6 list-kontak">
								<b>Admin SIBI</b><br>
								+62 811-8880-5220
							</p>
						</div>	
					</div>
					<div class="devider mt-2"></div>
					<div class="row mt-4">
						<div class="col-lg-12">
							<span><img src="../assets/icon-phone-circle.png" width="15" alt=""></span>
							<p class="ms-6 list-kontak">
								<b>Telepon</b><br>
								+62 21-380-4248
							</p>
						</div>	
					</div>
					<div class="devider mt-2"></div>
					<div class="row mt-4">
						<div class="col-lg-12">
							<span><img src="../assets/icon-email-circle.png" width="15" alt=""></span>
							<p class="ms-6 list-kontak">
								<b><i>E-mail</i></b><br>
								buku@kemdikbud.go.id
							</p>
						</div>	
					</div>
					<div class="devider mt-2"></div>
					<div class="row mt-5">
						<div class="col-6 mx-auto">
							<div class="row">
								<div class="col d-flex justify-content-end p-0">
									<a href="https://www.facebook.com/SIBIpusbuk/" target="_blank">
										<img src="../assets/icon-fb.png" width="36" alt="">
									</a>
								</div>
								<div class="col d-flex justify-content-center p-0">
									<a href="https://www.instagram.com/sibi_kemdikbud/" target="_blank">
										<img src="../assets/icon-ig.png" width="36" alt="">
									</a>
								</div>
								<div class="col d-flex justify-content-start p-0">
									<a href="https://www.youtube.com/channel/UCLm7X0R-hLHArgF88Z6OPQw" target="_blank">
										<img src="../assets/icon-yt.png" width="36" alt="">
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6 form-kontak">
					<div class="card no-border p-0 bg-bb border-round">
						<div class="card-body p-5">
							<div class="text-white text-bold mb-3">Kritik dan Saran</div>
							<form id="contact-form" v-on:submit.prevent="submitForm">
								<fieldset :disabled="loading">
									<div class="row">
										<div class="col-lg-12">
											<div class="contact-form-success alert alert-success mt-4 alert-dismissible fade show" v-if="success">
												<strong>Terima kasih.</strong> Kritik dan saran telah diterima.
												<button type="button" class="btn-close" aria-label="Close" v-on:click="success = !success"></button>
											</div>
											<div class="contact-form-error alert alert-danger mt-4 alert-dismissible fade show" v-if="error">
												<strong>Galat!</strong> Terjadi kesalahan ketika mengirim data.
												<button type="button" class="btn-close" aria-label="Close" v-on:click="error = !error"></button>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-lg-12 mb-4">
											<input type="text" name="" class="form-control" placeholder="Nama" v-model="form.name" required="">
										</div>
										<div class="col-lg-12 mb-4">
											<input type="email" name="" class="form-control" placeholder="E-mail" v-model="form.email" required="">	
										</div>
										<div class="col-lg-12 mb-4">
											<input type="text" name="" class="form-control" placeholder="Subjek" v-model="form.subject" required="">	
										</div>	
										<div class="col-lg-12">
											<textarea class="form-control" placeholder="Pesan" rows="5" v-model="form.message" required=""></textarea>
										</div>
									</div>
									<div class="row mt-5">
										<div class="col-lg-12 d-flex justify-content-end">
											<button class="btn btn-sm btn-orange w-auto px-3">Kirim</button>
										</div>
									</div>
								</fieldset>
							</form>
						</div>
					</div>	
				</div>
			</div>
		</div>
		<div class="container-fluid mt-10">
			<div class="row">
				<div class="col-lg-12">
					<div class="mapouter"><div class="gmap_canvas"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.965103115647!2d106.7976427!3d-6.26832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f199c5981c2b%3A0x8fa6c2c0e3838fa8!2sDirektorat%20PSMA%20Kemdikbudristek!5e0!3m2!1sid!2sid!4v1653216664794!5m2!1sid!2sid"  width="100%" height="500" id="gmap_canvas" allowfullscreen="" loading="lazy" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div></div>	
				</div>	
			</div>	
			<div class="row d-flex justify-content-center badge-lokasi mb-10">
				<div class="col-lg-10">
					<div class="card no-border p-0 bg-bb border-round">
						<div class="card-body p-5 text-center">
							<img class="mb-3" src="../assets/icon-location-circle.png" width="40" alt="">
							<p class="m-0 f-white">Jalan RS. Fatmawati Gd D Komplek Kemendikbudristek<br> Cipete, Jakarta 12410</p>
						</div>
					</div>		
				</div>	
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Kontak',
	data(){
		return{
			success: false,
            error: false,
            loading: false,
			form:{
				name: '',
				email: '',
				subject: '',
				message: ''
			}
		}
	},
	methods:{
		submitForm(){
			this.loading = true
			this.axios.post('https://api.buku.kemdikbud.go.id/api/entry/contact', this.form)
			.then((response) => {
				if(response.status === 200){
					this.reset();
					this.success = true;
				}else{
					this.error = true;
				}
			}).catch((error) => {
				this.error = true
				console.log(error)
			}).finally(() => {
				this.loading =  false
			});
		},
		reset(){
			this.success = false;
			this.error = false;
			for(let field in this.form){
				this.form[field] = null;
			}
		}
	}
}
</script>