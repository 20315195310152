<template>
	<div class="tentang">
		<div class="container">
			<div class="card-tentang mt-5">
				<div class="row">
					<div class="col-lg-3 d-flex	justify-content-start">
						<div class="card no-border img-card-tentang">
							<img src="../assets/sekilas-pusbuk.png" class="" alt="...">
						</div>
					</div>
					<div class="col">
					</div>
				</div>
				<div class="row">
					<div class="col"></div>
					<div class="col-lg-10 d-flex justify-content-end">
						<div class="card no-border text-card-tentang bg-wavesw-l-1">
							<div class="card-body pt-0">
								<h3 class="card-title f-white mb-3">Tentang Pusbuk</h3>
								<p class="card-text">Ibarat sebuah perjalanan panjang yang berliku, demikian pula sejarah perbukuan atau penerbitan buku di Indonesia. Sejatinya perbukuan di Indonesia telah dimulai sejak abad k-19 ketika Belanda melalui VOC memboyong mesin cetak ke Indonesia. Perbukuan di Indonesia tidak terlepas dari peran Pemerintah Hindia Belanda. Mereka memboyong mesin cetak, operator cetak, dan para ahli penerbitan untuk berkiprah di Indonesia.</p>
								<p class="card-text">Pusat Perbukuan telah menjadi bagian penting dalam lahirnya ekosistem perbukuan di Indonesia. Banyak fakta sejarah perkembangan perbukuan Indonesia yang belum terdokumentasikan dengan baik. Oleh karena itu, Pusat Perbukuan menganggap penting adanya sebuah buku berisi fakta-fakta sejarah tentang perkembangan perbukuan di Indonesia, khususnya kebijakan buku pendidikan. </p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg-dot-l-3">
			<div class="container visimisi mt-5 pb-5">
				<div class="row">
					<div class="col">
						<h3 class="card-title mt-2 mb-3">Visi</h3>
						<p>"Kementerian Pendidikan dan Kebudayaan mendukung Visi dan Misi Presiden untuk mewujudkan Indonesia Maju yang berdaulat, mandiri, dan berkepribadian melalui terciptanya Pelajar Pancasila yang bernalar kritis, kreatif, mandiri, beriman, bertakwa kepada Tuhan Yang Maha Esa, dan berakhlak mulia, bergotong royong, dan berkebinekaan global"</p>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<h3 class="card-title mt-2 mb-3">Misi</h3>
						<ul class="round">
							<li><p class="mb-2">Mewujudkan pendidikan yang relevan dan berkualitas tinggi, merata dan berkelanjutan, didukung oleh infrastruktur dan teknologi.</p></li>
							<li><p class="mb-2">Mewujudkan pelestarian dan pemajuan kebudayaan serta pengembangan bahasa dan sastra.</p></li>
							<li><p class="mb-2">Mengoptimalkan peran serta seluruh pemangku kepentingan untuk mendukung transformasi dan reformasi pengelolaan pendidikan dan kebudayaan.</p></li>
						</ul>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-8">
						<div class="row">
							<div class="col">
								<h3 class="card-title mt-2 mb-3">Tujuan</h3>
								
								<p>Tujuan penyusunan buku dalam ekosistem perbukuan Indonesia ini sangat penting, yaitu sebagai:</p>
				
								<ul class="round">
									<li><p class="mb-2">Mikrosejarah pemerintah Indonesia untuk diwariskan kepada generasi mendatang;</p></li>
									<li><p class="mb-2">Lesson learned tentang politik dan kebijakan perbukuan pemerintah dalam lintas sejarah;</p></li>
									<li><p class="mb-2">Referensi sejarah dalam konteks perbukuan yang dapat membantu penelitian ilmiah berbasis perbukuan; </p></li>
									<li><p class="mb-2">Manajemen pengetahuan yang terdokumentasikan; dan</p></li>
									<li><p class="mb-2">Referensi kebijakan perbukuan yang dapat menjadi rujukan penyusunan kebijakan perbukuan pada masa yang akan datang.</p></li>
								</ul>

							</div>
						</div>
						<div class="row">
							<div class="col">
								<h3 class="card-title mt-2 mb-3">Produk</h3>
								<p>Ikhtiar mengumpulkan informasi tentang perbukuan Indonesia ini bermuara pada lahirnya suatu produk berupa buku sejarah perbukuan Indonesia. Penyajian buku ini dibagi dalam dua fase sejarah. Fase pertama adalah era prakemerdekaan Indonesia, dan fase kedua adalah era pascakemerdekaan Indonesia. Era prakemerdekaan ditandai dengan munculnya penerbitan Hiindia Belanda, penerbitan Cina Peranakan, dan penerbitan Bumiputera. Era pascakemerdekaan dimulai dengan munculnya penerbitan swasta (partikelir), penerbitan pemerintah, dan penerbitan perguruan tinggi.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 text-center pt-5">
						<img src="../assets/visi-misi.png" alt="...">
					</div>
				</div>
			</div>
		</div>
		<div class="wrap-full bg-sm-trans-bw">
			<div class="container py-10">
				<div class="row">
					<div class="col">
						<h3 class="card-title mt-0 mb">Sejarah Singkat Pusbuk</h3>
					</div>
				</div>
				<div class="row mt-5">
					<div class="col-lg-12">
						<section class="timeline_area section_padding_130">
							<div class="container">
								<div class="row">
									<div class="col-12">
										<div class="apland-timeline-area" v-if="data_tls.length > 0">
											<div class="single-timeline-area"  v-for="(item, i) in data_tls" :key="i">
												<div class="timeline-date">
													<h5 class="text-end">{{ item.tahun }}</h5>
												</div>
												<div class="row py-3">
													<div class="col">
														<div class="single-timeline-content d-flex pt-3">
															<div class="timeline-icon">
																<img :src="require('../assets/'+item.image)" alt="...">
															</div>
														</div>
													</div>
													<div class="col-lg-8">
														<div class="single-timeline-content d-flex">
															<div v-if="!item.show_desk">
																<div class="timeline-text py-3" v-html="item.intro">
																</div>
																<span class="position-absolute"><button class="link-blue bg-transparent border-0 ps-0" v-on:click='showDesk(item.id)'>selengkapnya &gt;</button></span>
															</div>
															<div v-else>
																<div class="timeline-text py-3" v-html="item.deskripsi">
																</div>
																<span class="position-absolute"><button class="link-blue bg-transparent border-0 ps-0" v-on:click='hideDesk(item.id)'>&lt; kembali</button></span>
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-lg-10 mx-auto">
														<hr>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</div>
		<div class="wrap-full py-5 struktur bg-dot-l-1">
			<div class="container py-5">
				<div class="row">
					<div class="col text-center">
						<h3 class="card-title mt-0 mb">Struktur Organisasi</h3>
					</div>
				</div>
				<div class="row mt-5">
					<div class="col-lg-10 mx-auto">
						<img src="../assets/struktur-organisasi.png" class="img-struktur" alt="...">
					</div>
				</div>
				<div class="row mt-2">
					<div class="row mt-7">
						<div class="col-lg-3">
							<div class="card no-border">
								<div class="card-body px-0 py-2 text-center">
									<img class="mb-4 img-struktur-profil" src="../assets/supriyatno.png" width="100" alt="">
									<h6 class="card-title mb-2">Supriyatno, S.Pd., M.A.</h6>
									<p class="card-text mb-5">Kepala Pusat</p>
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="card no-border">
								<div class="card-body px-0 py-2 text-center">
									<img class="mb-4 img-struktur-profil" src="../assets/eka-setiawati.png" width="100" alt="">
									<h6 class="card-title mb-2">Eka Setiawati, S.Kom., M.Ak</h6>
									<p class="card-text mb-5">Kepala Sub Bagian Tata Usaha</p>
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="card no-border">
								<div class="card-body px-0 py-2 text-center">
									<img class="mb-4 img-struktur-profil" src="../assets/frandi-yuanda.png" width="100" alt="">
									<h6 class="card-title mb-2">Frandi Yuanda, M. Si</h6>
									<p class="card-text mb-5">Pejabat Pembuat Komitmen</p>
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="card no-border">
								<div class="card-body px-0 py-2 text-center">
									<img class="mb-4 img-struktur-profil" src="../assets/soni-rusiyoko.png" width="100" alt="">
									<h6 class="card-title mb-2">Soni Rusiyoko, A.Md</h6>
									<p class="card-text mb-5">Bendahara Pengeluaran</p>
								</div>
							</div>
						</div>
					</div>
					<div class="row justify-content-center">
						<div class="col-lg-3">
							<div class="card no-border">
								<div class="card-body px-0 py-2 text-center">
									<img class="mb-4 img-struktur-profil" src="../assets/helga-kurnia.png" width="100" alt="">
									<h6 class="card-title mb-2">Helga Kurnia, S.Si., M.Si</h6>
									<p class="card-text mb-5">Koordinator Penyusunan Regulasi, Pengembangan Sistem Informasi Perbukuan Indonesia, dan Pembinaan Pelaku Perbukuan</p>
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="card no-border">
								<div class="card-body px-0 py-2 text-center">
									<img class="mb-4 img-struktur-profil" src="../assets/lenny-puspita-ekawaty.png" width="100" alt="">
									<h6 class="card-title mb-2">Lenny Puspita Ekawaty, S.Kom., MMSI</h6>
									<p class="card-text mb-5">Koordinator Penyusunan, Pengembangan, dan Penilaian Buku SD, SMP, dan SMA</p>
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="card no-border">
								<div class="card-body px-0 py-2 text-center">
									<img class="mb-4 img-struktur-profil" src="../assets/wijanarko-adi-nugroho.png" width="100" alt="">
									<h6 class="card-title mb-2">Wijanarko Adi Nugroho, S.Si</h6>
									<p class="card-text mb-5">Koordinator Penyusunan, Pengembangan, dan Penilaian Buku PAUD, Diksus, dan Vokasi</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import tls_json from '@/assets/json/data_tlsejarah.json'
export default {
	name: 'Tentang',
	data(){
		return{
			data_tls: tls_json
		}
	},
	methods:{
		showDesk(id){
			const index = this.data_tls.findIndex(object => {
				return object.id === id;
			})
			this.data_tls[index].show_desk = true
		},
		hideDesk(id){
			const index = this.data_tls.findIndex(object => {
				return object.id === id;
			})
			this.data_tls[index].show_desk = false
		}
	}
}
</script>