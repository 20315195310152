<template>
	<div class="skeleton-card-loading">
		<div class="row skeleton-card" v-if="isLoading && typeGrid == 1">
			<div class="col-lg-4" v-for="i in lCard" :key="i">
				<div class="cards">
					<div class="card is-loading">
						<div class="image"></div>
						<div class="content">
							<h2></h2>
							<p></p>
							<button></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row skeleton-card" v-if="isLoading && typeGrid == 2">
			<div class="col-lg-12" v-for="i in lCard" :key="i">
				<div class="cards">
					<div class="card is-loading m-0 shadow-none rounded-0">
						<div class="image rounded-0 h-20-c"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="row skeleton-card" v-if="isLoading && typeGrid == 3">
			<div class="col-lg-3" v-for="i in lCard" :key="i">
				<div class="cards">
					<div class="card is-loading">
						<div class="image small-image"></div>
						<div class="content">
							<h2></h2>
							<p></p>
							<button></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row skeleton-card" v-if="isLoading && typeGrid == 4">
			<div class="col-lg-3" v-for="i in lCard" :key="i">
				<div class="cards">
					<div class="card is-loading">
						<div class="image"></div>
						<div class="content">
							<h2></h2>
							<p></p>
							<button></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row skeleton-card" v-if="isLoading && typeGrid == 5">
			<div class="col-lg-12" v-for="i in lCard" :key="i">
				<div class="cards">
					<div class="card is-loading">
						<div class="row">
							<div class="col-lg-3">
								<div class="image small-image-2"></div>
							</div>
							<div class="col-lg-9">
								<div class="content">
									<h2></h2>
									<p></p>
									<button></button>
								</div>	
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row skeleton-card" v-if="isLoading && typeGrid == 6">
			<div class="col-lg-12" v-for="i in lCard" :key="i">
				<div class="cards">
					<div class="card is-loading border-0 shadow-none">
						<div class="image rounded-5 h-20-c"></div>
						<div class="content px-0">
							<h2 class="mb-3"></h2>
							<p></p>
							<p class="me-5"></p>
							<p class="me-4"></p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row skeleton-card" v-if="isLoading && typeGrid == 7">
			<div class="col-lg-12" v-for="i in lCard" :key="i">
				<div class="cards">
					<div class="card is-loading">
						<div class="row">
							<div class="col-lg-3">
								<div class="image small-image-3"></div>
							</div>
							<div class="col-lg-9">
								<div class="content">
									<h2></h2>
									<p></p>
								</div>	
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row skeleton-card" v-if="isLoading && typeGrid == 8">
			<div class="col-lg-12" v-for="i in lCard" :key="i">
				<div class="cards">
					<div class="card is-loading border-0 shadow-none">
						<div class="image rounded-5 h-20-c"></div>
						<div class="content px-0">
							<h2 class="mb-3"></h2>
							<p></p>
							<button></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SkeletonCardLoading',
	props: ["isLoading","lCard", "typeGrid"]
}
</script>