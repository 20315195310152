<template>
	<div class="mx-auto my-4">
		<div class="loader" v-if="loading">
			<div class="row centered-loading">
				<div class="col-lg-12">
				<div class="spinner-grow text-info" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
				</div>
				<div class="col-lg-12 text-light">sedang mengambil data ...</div>
			</div>
		</div>
		<div v-else class="container">
			<div class="row justify-content-center">
				<div class="col-lg-7">
					<h1 class="h2 card-title my-3 lh-base text-blue">{{ news.title }}</h1>
					<div class="d-flex align-items-center gap-1 mb-3">
						<img src="https://cdn-icons-png.flaticon.com/512/6596/6596121.png" width="45" alt="">
						<div class="text-muted">
							<small class="d-block">{{ news.name }}</small>
							<small style="font-size: .7rem;">{{ news.published_at }}</small>
						</div>
					</div>
					<img :src="news.featured_image" class="w-100 rounded mb-4" alt="">
					<p class="card-text lh-lg" v-html="news.content"></p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
	name: "DetailBerita",
	data: () => ({
		news: [],
		loading: false,
	}),
	methods: {
		getNews() {
		this.loading = true;
		this.axios
			.get("https://api.buku.kemdikbud.go.id/api/post/" + this.$route.params.id)
			.then((response) => {
			this.news = response.data;
			})
			.catch((error) => {
			console.log(error);
			})
			.finally(() => {
			this.loading = false;
			});
		},
	},
	mounted() {
		this.getNews();
	},
};
</script>