<template>
	<div class="beranda">
		<skeleton-card-loading :isLoading="isLoadingSlide" :lCard="1" :typeGrid="2" />
		<vueper-slides v-if="slides.length > 0" fade :touchable="false" autoplay class="main-slide-beranda">
			<vueper-slide
			v-for="(slide, i) in slides" :key="i">
				<template v-slot:content>
					<div class="img-slider">
						<picture>
							<source media="(min-width: 800px)" :srcset="slide.image">
							<source media="(min-width: 450px)" :srcset="slide.image_for_mobile">
							<source media="(min-width: 250px)" :srcset="slide.image_for_mobile">
							<img :src="slide.image" alt="" v-on:click="goLinkSLide(slide.link)">
						</picture>
					</div>
				</template>
			</vueper-slide>
		</vueper-slides>
		<div class="bg-dot-l-1">
			<div class="container mt-7">
				<div class="row">
					<div class="col">
						<div class="card box-shadow-round no-border sm-no-bs">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-4">
										<div class="card card-service no-border">
											<div class="card-body">
												<img class="mb-4 mt-2" src="../assets/icon-e-catalog.png" width="100" alt="">
												<h5 class="card-title mb-0">Buku Elektronik</h5>
												<p class="card-text p-4 mb-1 mt-0">Platform digital berisi buku pendidikan dalam berbagai format (pdf, audio, dan interaktif) untuk mendukung siswa dan guru dalam kegiatan belajar mengajar.</p>
												<a href="https://buku.kemdikbud.go.id" target="_blank" class="btn btn-sm btn-orange">Selengkapnya</a>
											</div>
										</div>
									</div>
									<div class="col-lg-4 container-card-service">
										<span class="vr"></span>
										<hr>
										<div class="card card-service no-border">
											<div class="card-body">
												<img class="mb-4 mt-2" src="../assets/icon-pelaku-perbukuan.png" width="100" alt="">
												<h5 class="card-title mb-0">Pelaku Perbukuan</h5>
												<p class="card-text p-4 mb-1 mt-0">Platform digital untuk mendukung administrasi dalam kegiatan pendaftaran, pembinaan, pengajuan HET, penilaian, dan sertifikasi untuk pelaku perbukuan.</p>
												<a href="https://app.buku.kemdikbud.go.id/" target="_blank" class="btn btn-sm btn-orange">Selengkapnya</a>
											</div>
										</div>
									</div>
									<div class="col-lg-4 container-card-service">
										<span class="vr"></span>
										<hr>
										<div class="card card-service no-border">
											<div class="card-body">
												<img class="mb-4 mt-2" src="../assets/icon-penilaian.png" width="100" alt="">
												<h5 class="card-title mb-0">Penilaian Buku</h5>
												<p class="card-text p-4 mb-1 mt-0">Sistem informasi berbasis daring untuk mewadahi kegiatan penilaian buku teks utama dan buku nonteks, mulai dari pendaftaran sampai pengumuman akhir.</p>
												<a href="https://app.buku.kemdikbud.go.id/penilaian" target="_blank" class="btn btn-sm btn-orange">Selengkapnya</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid mt-7">
			<div class="row bg-bw bg-waveso-l-1">
				<div class="col-lg-5 g-0">
					<div class="card card-sekilas no-border">
						<img src="../assets/thumb-sekilas-pusbuk.png" class="img-sekilas" alt="...">
					</div>
				</div>
				<div class="col-lg-7">
					<div class="card card-sekilas no-border">
						<div class="card-body pt-0 sm-text-center">
							<h3 class="card-title mb-3">Sekilas Tentang Pusbuk</h3>
							<p class="card-text">Pusbuk atau kependekan dari Pusat Perbukuan adalah satuan pusat di bawah Badan Standar, Kurikulum, dan Asesmen Pendidikan (BSKAP), Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi. Pusbuk memiliki tugas melaksanakan pengembangan, pembinaan, dan pengawasan sistem perbukuan di Indonesia.</p>
							<button class="btn btn-sm btn-orange mt-4" v-on:click="go('/tentang')">Selengkapnya</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg-dot-l-3">
			<div class="container proker py-10">
				<div class="row">
					<div class="col">
						<h3 class="card-title mt-0 mb">Program Kerja</h3>
						<h6 class="card-subtitle mt-0 mb-4">Berbagai kegiatan yang dilaksanakan dalam Pusat Perbukuan</h6>
					</div>
				</div>
				<skeleton-card-loading :isLoading="isLoadingProker" :lCard="4" :typeGrid="3" />
				<carousel v-if="data_proker.length > 0" :breakpoints="{1280: { itemsToShow: 3.5}, 800: { itemsToShow: 2.5},250: { itemsToShow: 1} }">
					<slide v-for="(item,i) in data_proker" :key="i">
						<div class="row">
							<div class="col-lg-12 my-3">
								<div class="card box-shadow-round no-border h-25-c mx-2">
									<div class="card-body px-5 py-4 text-start">
										<img class="mb-4 b-r-1" src="../assets/thumbnail-program-kerja.png" width="100" alt="">
										<h5 class="card-title mb-2">{{ item.title }}</h5>
										<p class="card-text mb-1">{{ item.intro }}</p>
										<button class="link-orange b-40-p-a bg-transparent border-0 ps-0" v-on:click="go('/program/detail/'+item.id+'/'+item.slug);">Selengkapnya</button>
									</div>
								</div>
							</div>
						</div>
					</slide>
					<template #addons>
						<navigation />
						<pagination />
					</template>
				</carousel>
				<div class="row mt-4">
					<div class="col-lg-12 text-center">
						<button class="btn btn-sm btn-orange w-auto px-4" v-on:click="go('/program');" v-if="data_proker.length > 0">LIHAT SEMUA</button>
					</div>	
				</div>
			</div>
		</div>
		<div class="wrap-full bg-bb produk bg-dot-l-2">
			<div class="container py-10">
				<div class="row">
					<div class="col">
						<h3 class="card-title mt-0 mb f-white">Produk</h3>
						<h6 class="card-subtitle mt-0 mb-4 f-white">Berikut ini berbagai produk yang dihasilkan dalam kegiatan Pusat Perbukuan</h6>
					</div>
				</div>
				<skeleton-card-loading :isLoading="isLoadingProduk" :lCard="4" :typeGrid="4"/>
				<carousel v-if="data_produk.length > 0" :breakpoints="{1280: { itemsToShow: 3.5}, 800: { itemsToShow: 2.5},250: { itemsToShow: 1} }">
					<slide v-for="(item,i) in data_produk" :key="i">
						<div class="row">
							<div class="col-lg-12 my-3">
								<div class="card box-shadow-round no-border h-35-c mx-2">
									<img :src="item.featured_image" class="card-img-top" alt="...">
									<div class="card-body px-5 py-4 text-start">
										<h5 class="card-title mb-3">{{item.title}}</h5>
										<p class="card-text mb-4">{{item.intro}}</p>
										<button class="btn btn-sm btn-orange mb-4 b-15-p-a" v-on:click="go('/produk/detail/'+item.id+'/'+item.slug);">Selengkapnya</button>
									</div>
								</div>
							</div>
						</div>
					</slide>
					<template #addons>
						<navigation />
						<pagination />
					</template>
				</carousel>
				<div class="row mt-4">
					<div class="col-lg-12 text-center">
						<button class="btn btn-sm btn-orange w-auto px-4" v-on:click="go('/produk');" v-if="data_produk.length > 0">LIHAT SEMUA</button>
					</div>	
				</div>
			</div>
		</div>
		<div class="bg-dot-l-4 faq">
			<div class="container mt-10 pb-10">
				<div class="row">
					<div class="col">
						<h3 class="card-title mt-0 mb">Pertanyaan yang sering ditanyakan</h3>
					</div>
				</div>
				<div class="row mt-4">
					<div class="col order-2 order-md-1">
						<div class="accordion" id="accordionFaQ" v-if="faq.length > 0">
							<div class="accordion-item no-border bb-1" v-for="(item, i) in faq" :key="i">
								<h2 class="accordion-header" :id="'heading'+item.id">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+item.id" aria-expanded="false" :aria-controls="'collapse'+item.id">
										{{item.question}}
									</button>
								</h2>
								<div :id="'collapse'+item.id" class="accordion-collapse collapse" :aria-labelledby="'heading'+item.id" data-bs-parent="#accordionFaQ">
									<div class="accordion-body">
										<div v-html="item.answere"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col pt-0 text-center order-1">
						<img class="mb-4" src="../assets/FAQ.png" width="435" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import faq_json from '@/assets/json/data_faq.json'

import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

import SkeletonCardLoading from '@/views/layout/SkeletonCardLoading.vue'

export default {
	name: 'Beranda',
	components: {
		VueperSlides, 
		VueperSlide,
		Carousel,
		Slide,
		Pagination,
		Navigation,
		SkeletonCardLoading
	},
	data(){
		return{
			slides: [],
			isLoadingSlide: true,
			isLoadingProker: true,
			isLoadingProduk: true,
			data_proker: [],
			data_produk: [],
			faq: faq_json
		}
	},
	created () {
		this.getSLider()
		this.getProgram()
		this.getProduk()
	},
	methods: {
		go(link){
			this.$router.push(link);
		},
		goLinkSLide(link){
			window.open(link);
		},
		getSLider() {
			this.axios.get('https://api.buku.kemdikbud.go.id/api/entry/slider?direction=desc').then((response) => {
				this.slides = response.data.results
				this.isLoadingSlide = false
			})
		},
		getProgram() {
			this.axios.get('https://api.buku.kemdikbud.go.id/api/post?page=1&perpage=10&type=program').then((response) => {
				this.data_proker = response.data.posts
				this.isLoadingProker = false
			})
		},
		getProduk() {
			this.axios.get('https://api.buku.kemdikbud.go.id/api/post?page=1&perpage=10&type=product').then((response) => {
				this.data_produk = response.data.posts
				this.isLoadingProduk = false
			})
		},
	}
}
</script>
