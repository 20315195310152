<template>
	<div class="main-produk">
		<div class="container mt-5">
			<div class="row g-5">
				<div class="col-lg-12">
					<div class="row">
						<div class="col-lg-8 d-flex justifi-content-start">
							<h3>Produk</h3>
						</div>
						<div class="col-lg-4 d-flex justifi-content-end">
							<div class="input-group mb-3">
								<input type="text" class="form-control" placeholder="Cari" v-model="search">
								<span class="input-group-text"><i class="bi bi-search"></i></span>
							</div>
						</div>
					</div>
					<div class="devider mt-1 mb-4"></div>
					<div class="row mt-3" v-if="search">
						<div class="col-lg-12">
							<p>Hasil Pencarian untuk : <b>{{search}}</b></p>
						</div>
					</div>
					<skeleton-card-loading :isLoading="isLoadingProduk" :lCard="4" :typeGrid="5"/>
					<div class="row py-4" v-for="(item, i) in data_produk" :key="i">
						<div class="col-lg-12">
							<div class="card box-shadow-round no-border py-4 px-4">
								<div class="row">
									<div class="col-lg-4">
										<img :src="item.featured_image" class="card-img-top box-shadow-round" alt="...">
									</div>
									<div class="col-lg-8">
										<h5 class="card-title mb-3">{{ item.title }}</h5>
										<p class="card-text mb-4 pe-5">{{ item.intro }}</p>
										<a href="#" class="btn btn-sm btn-orange mt-2" v-on:click="go('/produk/detail/'+item.id+'/'+item.slug);">Selengkapnya</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SkeletonCardLoading from '@/views/layout/SkeletonCardLoading.vue'
export default {
	name: 'MainProduk',
	components:{
		SkeletonCardLoading
	},
	data(){
		return{
			search: '',
			isLoadingProduk: true,
			postList: []
		}
	},
	created(){
		this.getProduk()
	},	
	methods: {
		go(link){
			this.$router.push(link);
		},
		getProduk() {
			this.axios.get('https://api.buku.kemdikbud.go.id/api/post?page=1&perpage=20&type=product').then((response) => {
				this.postList = response.data.posts
				this.isLoadingProduk = false
			})
		}
	},
	computed: {
		data_produk() {
			return this.postList.filter(object => {
				return object.title.toLowerCase().includes(this.search.toLowerCase())
			})
		}
	}
}
</script>