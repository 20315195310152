<template>
  <div class="mx-auto my-4">
    <div class="loader" v-if="loading">
      <div class="row centered-loading">
        <div class="col-lg-12">
          <div class="spinner-grow text-info" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="col-lg-12 text-light">sedang mengambil data ...</div>
      </div>
    </div>
    <div v-else>
      <div
        v-if="isCertificateValid"
        class="d-flex justify-content-center align-items-center flex-column"
        style="gap: 1rem"
      >
        <div>
          <img
            src="../assets/sertifikat-valid.svg"
            style="width: 300px"
            alt=""
          />
          <h2 class="text-center font-weight-bold">Sertifikat Valid!</h2>
        </div>
        <div
          class="border-top pt-4 px-3"
          style="min-width: 300px; max-width: 500px"
        >
          <div class="d-flex align-items-center">
            <h4 class="pl-1 font-weight-bold">{{ certificateData.name }}</h4>
            <small class="text-muted"
              >&nbsp;({{ certificateData.role }})
            </small>
          </div>
          <div class="d-flex h5">
            <span>Mata Pelajaran: </span>
            <span class="px-3 text-muted"> {{ certificateData.subject }}</span>
          </div>
          <div class="d-flex h5">
            <span>Event: </span>
            <span class="px-3 text-muted"> {{ certificateData.event }}</span>
          </div>
          <div class="d-flex h5">
            <span class="text-nowrap">Terbit pada: </span>
            <span class="px-3 text-muted">
              {{ certificateData.created_at }}</span
            >
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <img
          src="../assets/sertifikat-invalid.svg"
          style="width: 300px"
          alt=""
        />
        <h2>Sertifikat Tidak Valid!</h2>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Sertifikat",
  data: () => ({
    isCertificateValid: false,
    certificateData: null,
    loading: false,
  }),
  methods: {
    getSertificate() {
      this.loading = true;
      this.axios
        .get(
          "https://penilaian-buku.cloudapp.web.id/api/penilaian/certificate/getDetail/" +
            this.$route.params.id
        )
        .then((res) => {
          this.certificateData = res.data;
          console.log(res.data);
          this.isCertificateValid = true;
        })
        .catch(() => {
          this.isCertificateValid = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getSertificate();
  },
};
</script>
