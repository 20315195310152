<template>
	<div class="main-program">
		<div class="container mt-5">
			<!-- <div class="row">
				<div class="card box-shadow-round no-border card-baner-head p-0">
					<img src="@/assets/banner-program.jpg" class="card-img-top w-100" alt="...">
					<div class="card-body px-7 py-4">
						<h3 class="card-title mb-2 f-white">Penyusunan Buku</h3>
						<p class="card-text mb-4 f-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
					</div>
				</div>
			</div> -->
			<div class="row mt-7">
				<div class="col">
					<h3 class="card-title mt-0 mb">Program Kerja</h3>
					<h6 class="card-subtitle mt-0 mb-4">Berbagai kegiatan yang dilaksanakan dalam Pusat Perbukuan</h6>
				</div>
			</div>
			<skeleton-card-loading :isLoading="isLoading" :lCard="3" :typeGrid="1" class="mt-5 mb-5"/>
			<div class="row mt-5 mb-5">
				<div class="col-lg-4 mb-5" v-for="(item, i) in data_proker" :key="i">
					<div class="card box-shadow-round no-border">
						<img :src="item.featured_image" class="card-img-top" alt="...">
						<div class="card-body px-5 pt-4 pb-5">
							<h5 class="card-title mb-2">{{ item.title }}</h5>
							<p class="card-text mb-4">{{ item.intro }}</p>
							<a href="#" class="btn btn-sm btn-orange mb-4" v-on:click="go('/program/detail/'+item.id+'/'+item.slug);">Selengkapnya</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SkeletonCardLoading from '@/views/layout/SkeletonCardLoading.vue'
export default {
	name: 'MainProgram',
	components:{
		SkeletonCardLoading
	},
	data(){
		return{
			isLoading: true,
			data_proker: []
		}
	},
	created(){
		this.getProgram()
	},
	methods: {
		go(link){
			this.$router.push(link);
		},
		getProgram() {
			this.axios.get('https://api.buku.kemdikbud.go.id/api/post?page=1&perpage=20&type=program').then((response) => {
				this.data_proker = response.data.posts
				this.isLoading = false
			})
		}
	}
}
</script>