<template>
	<div class="main-produk">
		<div class="container mt-5">
			<div class="row g-5">
				<div class="col-lg-12">
					<div class="row">
						<div class="col-lg-8 d-flex justifi-content-start">
							<h3>Berita</h3>
						</div>
						<div class="col-lg-4 d-flex justifi-content-end">
							<div class="input-group mb-3">
								<input type="text" class="form-control" placeholder="Cari" v-model="search">
								<span class="input-group-text"><i class="bi bi-search"></i></span>
							</div>
						</div>
					</div>
					<div class="devider mt-1 mb-4"></div>
					<div class="row mt-3" v-if="search">
						<div class="col-lg-12">
							<p>Hasil Pencarian untuk : <b>{{search}}</b></p>
						</div>
					</div>
					<skeleton-card-loading :isLoading="loading" :lCard="4" :typeGrid="5"/>
					<div class="row py-4">
						<div class="col-lg-3 mb-4" v-for="(item, i) in filteredNews" :key="i">
							<div class="card box-shadow-round no-border">
								<div class="card-img card-img-top ">
									<img :src="item.featured_image" class="object-fit-cover w-100" height="300" :alt="item.title">
								</div>
								<div class="card-body">
									<h5 role="button" class="card-title " v-on:click="go('/berita/detail/'+item.id+'/'+item.slug);">{{ item.title }}</h5>
									<p>{{ item.intro }}</p>
									<a href="#" class="btn btn-sm btn-orange mt-2" v-on:click="go('/berita/detail/'+item.id+'/'+item.slug);">Baca</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SkeletonCardLoading from '@/views/layout/SkeletonCardLoading.vue'
import axios from 'axios';

export default {
	name: 'MainProduk',
	components:{
		SkeletonCardLoading
	},
	data(){
		return{
			search: '',
			loading: false,
			news: []
		}
	},
	methods: {
		go(link){
			this.$router.push(link);
		},
		getNews() {
		this.loading = true;
		axios
			.get("https://api.buku.kemdikbud.go.id/api/post?page=1&perpage=20&type=news")
			.then((response) => {
				this.news = response.data.posts;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
			});
		},
	},
	computed: {
		filteredNews() {
			return this.news.filter((item) => {
				return item.title.toLowerCase().includes(this.search.toLowerCase());
			});
		}	
	},
	created(){
		this.getNews()
	},
}
</script>