<template>
	<div class="program">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'Program'
}
</script>