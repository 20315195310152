<template>
	<div class="detail-produk">
		<div class="container mt-5 mb-5">
			<div class="row g-3">
				<div class="col-lg-7">
					<skeleton-card-loading :isLoading="isLoadingQ" :lCard="1" :typeGrid="6"/>
					<div class="row">
						<div class="col-lg-12">
							<img :src="data_query.featured_image" class="">	
						</div>
					</div>
					<div class="row mt-5">
						<div class="col-lg-12">
							<h3 class="mb-3" v-if="data_query">{{ data_query.title }}</h3>
							<div v-if="data_query" v-html="data_query.content">
								
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-5 right-list-produk">
					<div class="row">
						<div class="col-lg-12">
							<div class="input-group mb-3">
								<input type="text" class="form-control" placeholder="Cari" v-model="search">
								<span class="input-group-text"><i class="bi bi-search"></i></span>
							</div>
						</div>
					</div>
					<div class="devider mt-1 mb-4"></div>
					<div class="row mt-3" v-if="search">
						<div class="col-lg-12">
							<p>Hasil Pencarian untuk : <b>{{search}}</b></p>
						</div>
					</div>
					<skeleton-card-loading :isLoading="isLoadingProduk" :lCard="4" :typeGrid="7"/>
					<div v-if="data_produk.length > 0">
						<div class="row mt-3" v-for="(item,i) in data_produk" :key="i">
							<div class="col-lg-12">
								<span class="position-absolute"><img :src="item.featured_image" class="thumbnail" alt=""></span>
								<div class="ms-7">
									<a href="#" class="text-decoration-none h-p" v-on:click="goDetail(item)"><h5>{{ item.title }}</h5></a>
									<p>
										{{ item.intro }} 
									</p>
								</div>
							</div>	
						</div>
					</div>
					<div class="devider mt-4"></div>
					<skeleton-card-loading :isLoading="isLoadingQN" :lCard="4" :typeGrid="8"/>
					<div class="row" v-if="!isLoadingQN">
						<div class="col-lg-12 mt-5">
							<div class="card no-border">
								<img :src="next_query.featured_image" class="full" alt="...">
								<div class="card-body px-0">
									<h5 class="card-title py-1" v-if="next_query">{{ next_query.title }}</h5>
									<p class="card-text mb-4" v-if="next_query">{{ next_query.intro }}</p>
									<a href="#" class="btn btn-sm btn-orange mb-4" v-on:click="goDetail(next_query);">Selengkapnya</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SkeletonCardLoading from '@/views/layout/SkeletonCardLoading.vue'
export default {
	name: 'DetailProduk',
	components:{
		SkeletonCardLoading
	},
	data(){
		return{
			search: '',
			isLoadingQ: true,
			isLoadingProduk: true,
			isLoadingQN: true,
			data_query: {},
			next_query: {},
			postList: []
		}
	},
	created(){
		this.get_d(this.$route.params.id)
		this.getProduk()
	},
	methods:{
		goDetail(item){
			this.isLoadingQ = true
			this.isLoadingQN = true
			this.$router.push({ name: 'DetailProduk', params: { id: item.id, slug: item.slug } });
			this.get_d(item.id)
			this.generatenexq(item.id)
		},
		get_d(id){
			this.axios.get('https://api.buku.kemdikbud.go.id/api/post/'+id).then((response) => {
				this.data_query = response.data
				this.isLoadingQ = false
			})
		},
		getProduk() {
			this.axios.get('https://api.buku.kemdikbud.go.id/api/post?page=1&perpage=10&type=product').then((response) => {
				this.postList = response.data.posts
				this.isLoadingProduk = false
				this.generatenexq(this.$route.params.id)
			})
		},
		generatenexq(id){
			this.isLoadingQN = true;
			const index = this.postList.findIndex(object => {
				return object.id === id;
			})
			if(index+1 < this.postList.length){
				this.next_query = this.postList[index+1];
			}else{
				this.next_query = this.postList[0];
			}
			this.isLoadingQN = false;
		}
	},
	computed: {
		data_produk() {
			return this.postList.filter(object => {
				return object.title.toLowerCase().includes(this.search.toLowerCase())
			})
		}
	}
}
</script>